@charset "UTF-8";
/* CSS Document */
@import "_setting";

* {
  margin: 0;
  padding: 0;
  list-style: none;
  @include font();
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  text-decoration: none;
  color: #444;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  box-sizing: border-box;
}

span {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

body {
  background: #e9fffc;
  background: #e9fffc url(../images/bg/skyloop.jpg);
  //background-size: 100%;
}

a {
  transition-delay: 0;
  transition-duration: 0.1s;
  transition-property: all;
  transition-timing-function: linear;
}

br.sp {
  display: none;
}

.sp-only {
  display: none;
}

.pc-only {
  display: inherit;
}

.float {
  &:after {
    content: "";
    width: 0;
    height: 0;
    display: block;
    visibility: hidden;
    clear: both;
  }
  > .left {
    float: left;
  }
  > .right {
    float: right;
  }
}

.wrapper {
  width: 1000px;
  min-height: 100vh;
  margin: 0 auto;
  position: relative;
  > .left {
    position: fixed;
    top: 0;
    //left: 0;
    bottom: 0;
  }
  > .right {
  }
}

#main {
  width: 760px;
  margin-left: 240px;
  padding: 65px 0 114px;
  > .inner {
    background: rgba(#fff, 0.75);
    box-shadow: 0 0 2px 1px rgba(#000, 0.15);
    border-radius: 5px;
    padding: 60px 60px 10px;
    min-height: 843px;
    h1 {
      color: #4a62bc;
      font-size: 32px;
      line-height: 1.5em;
      letter-spacing: 0.5em;
      border-bottom: 2px solid #4a62bc;
      margin-bottom: 25px;
    }
  }
}

@import "PCside";
@import "PCfooter";
@import "PCsection";
@import "PChome";

//Tablet
//@media screen and (max-width: $tablet_width) {
//  //#main {
//  //  > .inner {
//  //    h1 {
//  //      font-size: 32px;
//  //    }
//  //  }
//  //}
//}

//SP
@media screen and (max-width: $sp_width) {
  br.sp {
    display: inline;
  }
  .sp-only {
    display: inherit;
  }
  .pc-only {
    display: none !important;
  }
  .float {
    > .left {
      float: none;
    }
    > .right {
      float: none;
    }
  }
  .sp-header {
    width: 100%;
    height: 16vw;
    background-image: url(../images/header-sp.png);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    .logo1{
      position: fixed;
      top: 2vw;
      left: 2vw;
      width: 17vw;
      img {
        width: 100%;
        height: auto;
        content: url(../images/logo1_sp.png);
      }
    }
    .logo2 {
      position: fixed;
      top: 2vw;
      left: 22vw;
      width: 27vw;
      img {
        width: 100%;
        height: auto;
        content: url(../images/logo2_sp.png);
      }
    }
    .logo3 {
      position: fixed;
      top: 9vw;
      left: 22vw;
      width: 27vw;
      img {
        width: 100%;
        height: auto;
        content: url(../images/logo3_sp.png);
      }

    }
  }
  .wrapper {
    width: auto;
    height: auto;
    .left {
      position: static;
    }
    .right {
    }
  }
  #main.right {
    padding-top: 17vw;
  }
  #main {
    width: auto;
    margin: 0;
    padding: 0;
    > .inner {
      margin: 5vw 5vw 5vw;
      padding: 5vw;
      h1 {
        font-size: 18px;
        margin-bottom: 5vw;
      }
      h2 {
        font-size: 20px;
        margin: 5vw 0;
        padding: 2.5vw;
      }

    }
  }
  @import "SPside";
  @import "SPfooter";
  @import "SPsection";
  @import "SPhome";
}
