section {
  margin-bottom: 4rem;
  &:last-child {
    margin-bottom: 1rem;
  }
  h2 {
    color: #fff;
    background-color: #4a62bc;
    border: 2px solid #fff;
    border-radius: 3px;
    box-shadow: 0 0 1.5px 1.5px rgba(#808080, 0.375);
    margin-top: 25px;
    margin-bottom: 25px;
    font-size: 32px;
    letter-spacing: 0.1em;
    text-align: center;
    padding: 3px;
    //opacit: 0.75;
    font-weight: 200;
  }
  h3 {
    color: #fff;
    background-color: #66a93a;
    border-radius: 3px;
    margin-top: 25px;
    margin-bottom: 10px;
    font-size: 18px;
    letter-spacing: 0.1em;
    text-align: center;
    padding: 3px;
    opacity: 0.75;
    font-weight: 500;
  }
  p {
    margin: 2em 0;
    line-height: 2em;
    text-align: justify;
  }
  &.green {
    p {
      color: #4a62bc;
      font-size: 15px;
      text-align: center;
      @include font(mincho);
    }
  }
  &.aisatsu {
    .left {
      width: 204px;
      p.photo {
        margin: 0 0 0.5em;
      }
      dl {
        text-align: center;
        border-bottom: 1px solid #444;
        padding-bottom: 3px;
        letter-spacing: 0.2em;
        dt {
          font-size: 13px;
        }
        dd {
          font-size: 22px;
        }
      }
    }
    .right {
      width: 393px;
      p {
        &:first-of-type {
          margin-top: -0.5em;
        }
      }
    }
  }
  &.enkaku {
    > dl {
      border-bottom: 1px solid #444;
      display: block;
      > dt {
        color: #8ac762;
        display: table-cell;
        vertical-align: top;
        padding: 0.6rem 0 0;
        width: 48px;
        text-align: center;
        @include font(alphabet);
        font-size: 22px;
        span {
          font-size: inherit;
          color: inherit;
          display: block;
          font-family: inherit;
          margin: -4px 0 0;
        }
      }
      &:nth-of-type(3n-1) {
        > dt {
          color: #62c7c1;
        }
      }
      &:nth-of-type(3n) {
        > dt {
          color: #e4be38;
        }
      }
      &:last-of-type {
        border-bottom: none;
      }
      > dd {
        display: table-cell;
        vertical-align: top;
        dl {
          display: block;
          border-bottom: 1px dashed #444;
          &:last-of-type {
            border-bottom: none;
          }
          dt {
            font-size: 12px;
            display: table-cell;
            vertical-align: top;
            padding: 1rem 0;
            width: 107px;
            border-right: 1px solid #444;
          }
          dd {
            font-size: 12px;
            display: table-cell;
            vertical-align: top;
            &:nth-of-type(1) {
              width: 427px;
              padding: 1rem 0 1rem 1rem;
            }
            &:nth-of-type(2) {
              width: 58px;
              font-size: 14px;
              padding: 1rem 0;
              span {
                font-size: 22px;
                line-height: 14px;
                vertical-align: inherit;
                @include font(alphabet);
              }
            }
          }
        }
      }
    }
  }
  &.class {
    h2 {
      background: #61983d;
      margin-bottom: 10px;
    }
    &:nth-of-type(3n-1) {
      h2 {
        background: #52b6af;
      }
    }
    &:nth-of-type(3n) {
      h2 {
        background: #dfc261;
      }
    }
    .mainphoto {
      margin: 10px 0;
      img {
        width: 100%;
        height: auto;
        display: block;
      }
    }
    .float {
      margin: 10px 0;
      .left {
        width: 258px;
      }
      .right {
        width: 364px;
      }
      p {
        margin: 0 0 10px;
      }
      .catch {
        margin: 0 0 10px;
        font-size: 24px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        height: 251px;
        color: #fff;
        padding: 16px 0 0 21px;
        font-weight: 200;
        line-height: 38px;
        letter-spacing: -0.02em;
        .bold {
          font-weight: 500;
        }
        img {
          width: 258px;
          height: auto;
          display: block;
        }
      }
      .sub {
        margin: 10px 0;
        img {
          width: 258px;
          height: auto;
          display: block;
        }
      }
      &:nth-of-type(2n) {
        .left {
          width: 364px;
        }
        .right {
          width: 258px;
        }
      }
    }
    .list {
      margin: 36px 0;
      dt {
        color: #61983d;
        font-size: 20px;
        text-align: center;
        padding-bottom: 0.5em;
      }
      dd {
        ol {
          text-align: center;
          li {
            width: 155px;
            display: inline-block;
            img {
              width: 155px;
              height: auto;
              display: block;
            }
          }
        }
      }
    }
    &#nou .catch {
      background-image: url(../images/merit_nou_catch.jpg);
    }
    &#kyuukyuu .catch {
      background-image: url(../images/merit_kyuukyuu_catch.jpg);
    }
    &#gan .catch {
      background-image: url(../images/merit_gan_catch.jpg);
    }
    &#shinzou .catch {
      background-image: url(../images/merit_shinzou_catch.jpg);
    }
    &#fukushi .catch {
      background-image: url(../images/merit_fukushi_catch.jpg);
    }
    &#touseki .catch {
      background-image: url(../images/merit_touseki_catch.jpg);
    }
    &#shuusanki .catch {
      background-image: url(../images/merit_shuusanki_catch.jpg);
    }
    &#hyouka .catch {
      background-image: url(../images/merit_hyouka_catch.jpg);
    }
    &#shokuin .catch {
      background-image: url(../images/education_shokuin_catch.jpg);
    }
    &#rinshou .catch {
      background-image: url(../images/education_rinshou_catch.jpg);
    }
  }
  &.links {
    h2 {
      background: #8ac762;
    }
    h2 + p {
      color: #6c6c6c;
      font-size: 15px;
      border: 2px solid #6c6c6c;
      text-align: center;
      border-radius: 15px;
      width: 325px;
      margin-left: auto;
      margin-right: auto;
      &:before,
      &:after {
        content: "";
        width: 0;
        height: 0;
        border-color: transparent;
        border-width: 0.7em 0.5em;
        border-style: solid;
        border-top-color: #6c6c6c;
        display: inline-block;
        vertical-align: bottom;
      }
      &:before {
        margin-right: 0.5em;
      }
      &:after {
        margin-left: 0.5em;
      }
    }
    ul {
      li {
        margin: 0.75em 0;
        a {
          display: block;
          color: #66a93a;
          font-size: 18px;
          font-weight: 500;
          &:before {
            content: "";
            width: 1em;
            height: 1em;
            line-height: 1em;
            background: #66a93a;
            display: inline-block;
            margin-right: 1em;
            vertical-align: middle;
          }
        }
      }
    }
    &:nth-of-type(3n-1) {
      h2 {
        background: #d4b523;
      }
      h3 {
        background: #ba9f1d;
      }
      ul {
        li {
          a {
            color: #ba9f1d;
            &:before {
              background: #ba9f1d;
            }
          }
        }
      }
    }
  }
  &.facilities {
    h2 {
      background: #8ac762;
    }
    ul {
      width: 620px;
      margin: 0 auto;
      li {
        border: 2px solid rgba(#a6d589, 0.75);
        box-shadow: 0 0 1.5px 1.5px rgba(#808080, 0.375);
        background: #fff;
        width: 293px;
        padding: 7px;
        margin: 8.5px;
        border-radius: 3px;
        dt {
          font-size: 18px;
          color: #66a93a;
          border-bottom: 1px solid #66a93a;
          height: 47px;
          padding-bottom: 4px;
          vertical-align: middle;
          display: table-cell;
          line-height: 1.3em;
          width: 275px;
          span {
            display: block;
            color: inherit;
            font-size: 13px;
            line-height: 1em;
          }
        }
        dd {
          .left {
            width: 152px;
            .address {
              margin: 4px 0 0;
              font-size: 13px;
              line-height: 1.3em;
              height: 32px;
            }
            .tel {
              font-size: 18px;
              line-height: 1em;
              color: #66a93a;
              margin: 4px 0 0;
              @include font(alphabet);
              a {
                font-size: inherit;
                line-height: inherit;
                color: inherit;
                font-family: inherit;
              }
            }
            .web {
              margin: 8px 0 0;
              a {
                display: block;
                background: #a0d880;
                color: #fff;
                font-size: 18px;
                opacity: 0.75;
                text-align: center;
                padding: 14px 0;
                line-height: 1em;
                border-radius: 3px;
                border: 2px solid #fff;
                box-shadow: 0 0 1.5px 1.5px rgba(#808080, 0.375);
                &:hover {
                  opacity: 1;
                }
              }
            }
          }
          .right {
            width: 115px;
            p {
              margin: 5px 0 0;
              img {
                width: 100%;
                height: auto;
                display: block;
              }
            }
          }
        }
      }
    }
    &:nth-of-type(3n-1) {
      h2 {
        background: #d4b523;
      }
      ul {
        li {
          border: 2px solid rgba(#dec75a, 0.75);
          dt {
            color: #ba9f1d;
            border-bottom-color: #ba9f1d;
          }
          dd {
            .left {
              .address {
                height: 47px;
              }
              .tel {
                color: #ba9f1d;
              }
              .web {
                a {
                  background: #dec85a;
                }
              }
            }
          }
        }
      }
    }
    &:nth-of-type(3n) {
      h2 {
        background: #9d9d9d;
      }
      ul {
        li {
          border: 2px solid rgba(#c6c8c8, 0.75);
          dt {
            color: #7d7d7d;
            border-bottom-color: #7d7d7d;
          }
          dd {
            .left {
              .plan {
                background: #b4b6b5;
                color: #fff;
                border-radius: 3px;
                border: 2px solid #fff;
                box-shadow: 0 0 1.5px 1.5px rgba(#808080, 0.375);
                font-size: 18px;
                line-height: 1.5em;
                text-align: center;
                margin: 5px 0 0;
                padding: 7px 0;
              }
            }
          }
        }
      }
    }
  }
}

nav.class {
  text-align: center;
  margin-left: 7px;
  li {
    margin-right: 7px;
    margin-bottom: 7px;
    display: inline-block;
    a {
      background: #61983d;
      color: #61983d;
      border: 2px solid #fff;
      border-radius: 3px;
      box-shadow: 0 0 1.5px 1.5px rgba(#808080, 0.5);
      //opacity: 0.75;
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      width: 117px;
      height: 66px;
      font-size: 15px;
      line-height: 15px;
      letter-spacing: 0.1em;
      &:before {
        background: #61983d;
      }
    }
    &:nth-of-type(3n-1) {
      a {
        background: #5cbeb7;
        color: #5cbeb7;
        &:before {
          background: #5cbeb7;
        }
      }
    }
    &:nth-of-type(3n) {
      a {
        background: #d6ad2c;
        color: #d6ad2c;
        &:before {
          background: #d6ad2c;
        }
      }
    }
    &.big {
      a {
        color: #fff;
      }
    }
    &.small {
      margin-right: 20px;
      &:last-of-type {
        margin-right: 0;
      }
      a {
        background: transparent;
        border: none;
        box-shadow: none;
        width: auto;
        height: auto;
        &:before {
          content: "";
          width: 0.8em;
          height: 0.8em;
          line-height: 0.8em;
          display: inline-block;
          border: 2px solid #fff;
          border-radius: 3px;
          box-shadow: 0 0 1.5px 1.5px rgba(#808080, 0.5);
          margin-right: 0.5em;
          vertical-align: middle;
        }
      }
    }
  }
}