#main.home {
  padding: 0;
  h1 {
    padding-top: 100vw;
    > span {
      letter-spacing: 0.1em;
      margin-top: 3vw;
      padding: 0 5vw;
      font-size: 4vw;
      line-height: 1.4em;
      vertical-align: middle;
      span {
        margin-bottom: 1rem;
        font-size: 5.5vw;
      }
      br {
        display: inline;
      }
    }
  }

  .home-text {
    img {
      position: inherit;
      //top: 50%;
      //left: 0;
      width: 100%;
    }
  }

  .merit {
    margin-top: 1vw;
    @include display-flex;
    @include justify-content-center;
    @include flex-wrap-wrap;
    position: inherit;
    width: auto;
    .left {
      img {
        width: 100%;
      }
    }
    &.float {
      //padding-top: 80px
    }
    li {
      width: 29vw;
      height: 29vw;
      margin: 0 1vw 1vw 0;
      background-size: cover;
      &:nth-of-type(3n) {
        margin-right: 0;
      }
      .caption {
        font-size: 3vw;
        height: 7.5vw;
        padding-top: 2vw;
      }
      .num {
        @include display-flex;
        @include justify-content-center;
        @include align-items-center;
        height: 11vw;
        font-size: 13vw;
      }
      .title {
        letter-spacing: 0;
        font-size: 3.5vw;
        line-height: 1.2em;
        height: 8vw;
        width: 29vw;
      }
    }
  }
  .whatsnew {
    // width: 90vw;
    width: 100%;
    height: 50vw;
    padding: 5vw;
    margin: 5vw auto;
    top: 100%;
    position: inherit;
    h2 {
      font-size: 16px;
    }
    ul {
      margin-top: 5vw;
      height: 29vw;
      li {
        width: 70vw;
      }
    }
  }
}