$tablet_width: 512px;
$pc_width: 1024px;
$sp_width: 980px;

@mixin font($name:gothic) {
  @if $name == mincho {
    font-family: "YakuHanJP", "Sawarabi Mincho", "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  } @else if $name == minchou {
    font-family: "YakuHanJP", "Sawarabi Mincho", "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  } @else if $name == alphabet {
    font-family: "YakuHanJP", "Ubuntu Condensed", sans-serif;
  } @else if $name == gothic {
    font-family: "YakuHanJP", "Noto Sans Japanese", "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
  } @else {
    font-family: "YakuHanJP", "Noto Sans Japanese", "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
  }
}

@mixin grad($start:#fff,$end:#000) {
  background: #{$start};
  background: -webkit-gradient(linear, left top, left bottom, from($start), to($end));
  background: -webkit-linear-gradient(top, $start 0%, $end 100%);
  background: -moz-linear-gradient(top, $start 0%, $end 100%);
  background: -o-linear-gradient(top, $start 0%, $end 100%);
  background: -ms-linear-gradient(top, $start 0%, $end 100%);
  background: linear-gradient(top, $start 0%, $end 100%);
  filter: progid:DXImageTransform.Microsoft.Gradient(Enabled=1, GradientType=0, StartColorStr=#{ie-hex-str($start)}, EndColorStr=#{ie-hex-str($end)});
}

@mixin transition($delay:0,$duration:0.1s,$property:all,$timingfunction:ease) {
  -webkit-transition-delay: $delay;
  -moz-transition-delay: $delay;
  -ms-transition-delay: $delay;
  -o-transition-delay: $delay;
  transition-delay: $delay;
  -webkit-transition-duration: $duration;
  -moz-transition-duration: $duration;
  -ms-transition-duration: $duration;
  -o-transition-duration: $duration;
  transition-duration: $duration;
  -webkit-transition-property: $property;
  -moz-transition-property: $property;
  -ms-transition-property: $property;
  -o-transition-property: $property;
  transition-property: $property;
  -webkit-transition-timing-function: $timingfunction;
  -moz-transition-timing-function: $timingfunction;
  -ms-transition-timing-function: $timingfunction;
  -o-transition-timing-function: $timingfunction;
  transition-timing-function: $timingfunction;
}

//@mixin anima{
//	0%{}
//	100%{}
//}
//@-webkit-keyframes mainbg { @include anima; }
//@-moz-keyframes mainbg { @include anima; }
//@-ms-keyframes mainbg { @include anima; }
//@-o-keyframes mainbg { @include anima; }
//@keyframes mainbg { @include anima; }
@mixin animation($name:name,$duration:10s,$timing:ease,$count:infinite,$direction:normal,$state:running,$delay:0) {
  -webkit-animation-name: $name;
  -webkit-animation-duration: $duration;
  -webkit-animation-timing-function: $timing;
  -webkit-animation-iteration-count: $count;
  -webkit-animation-direction: $direction;
  -webkit-animation-play-state: $state;
  -webkit-animation-delay: $delay;
  -moz-animation-name: $name;
  -moz-animation-duration: $duration;
  -moz-animation-timing-function: $timing;
  -moz-animation-iteration-count: $count;
  -moz-animation-direction: $direction;
  -moz-animation-play-state: $state;
  -moz-animation-delay: $delay;
  -ms-animation-name: $name;
  -ms-animation-duration: $duration;
  -ms-animation-timing-function: $timing;
  -ms-animation-iteration-count: $count;
  -ms-animation-direction: $direction;
  -ms-animation-play-state: $state;
  -ms-animation-delay: $delay;
  -o-animation-name: $name;
  -o-animation-duration: $duration;
  -o-animation-timing-function: $timing;
  -o-animation-iteration-count: $count;
  -o-animation-direction: $direction;
  -o-animation-play-state: $state;
  -o-animation-delay: $delay;
  animation-name: $name;
  animation-duration: $duration;
  animation-timing-function: $timing;
  animation-iteration-count: $count;
  animation-direction: $direction;
  animation-play-state: $state;
  animation-delay: $delay;
}

@mixin display-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin justify-content-flex-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}

@mixin justify-content-flex-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

@mixin justify-content-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

@mixin justify-content-space-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

@mixin flex-wrap-wrap {
  -webkit-box-lines: multiple;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

@mixin flex-wrap-nowrap {
  -webkit-box-lines: single;
  -ms-flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

@mixin align-items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

@mixin align-items-flex-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}