@charset "UTF-8";
/* CSS Document */
* {
  margin: 0;
  padding: 0;
  list-style: none;
  font-family: "YakuHanJP", "Noto Sans Japanese", "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  text-decoration: none;
  color: #444;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  box-sizing: border-box; }

span {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit; }

body {
  background: #e9fffc;
  background: #e9fffc url(../images/bg/skyloop.jpg); }

a {
  transition-delay: 0;
  transition-duration: 0.1s;
  transition-property: all;
  transition-timing-function: linear; }

br.sp {
  display: none; }

.sp-only {
  display: none; }

.pc-only {
  display: inherit; }

.float:after {
  content: "";
  width: 0;
  height: 0;
  display: block;
  visibility: hidden;
  clear: both; }

.float > .left {
  float: left; }

.float > .right {
  float: right; }

.wrapper {
  width: 1000px;
  min-height: 100vh;
  margin: 0 auto;
  position: relative; }
  .wrapper > .left {
    position: fixed;
    top: 0;
    bottom: 0; }

#main {
  width: 760px;
  margin-left: 240px;
  padding: 65px 0 114px; }
  #main > .inner {
    background: rgba(255, 255, 255, 0.75);
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    padding: 60px 60px 10px;
    min-height: 843px; }
    #main > .inner h1 {
      color: #4a62bc;
      font-size: 32px;
      line-height: 1.5em;
      letter-spacing: 0.5em;
      border-bottom: 2px solid #4a62bc;
      margin-bottom: 25px; }

.stickyinner.sticky {
  position: fixed;
  padding-left: 12px;
  top: 0; }

#side {
  background-color: rgba(5, 26, 106, 0.7);
  background-position: 12px 0;
  width: 218px;
  height: 100%; }
  #side .inner {
    width: 193px;
    margin: 0 auto;
    background: url(../images/nav_bg.png) 0 0 repeat-y;
    padding-left: 10px;
    padding-bottom: 40px; }
    #side .inner .glonav {
      padding: 0 0 150px; }
  #side .logo1,
  #side .logo2,
  #side .logo3 {
    width: 185px;
    border-bottom: 1px solid #fff; }
    #side .logo1 img,
    #side .logo2 img,
    #side .logo3 img {
      display: block;
      margin: 0 auto; }
  #side .logo1 {
    padding: 40px 0 45px; }
    #side .logo1 img {
      width: 138px;
      height: 104px; }
  #side .logo2 {
    padding: 20px 0; }
    #side .logo2 img {
      width: 96px;
      height: 51px; }
  #side .logo3 {
    padding: 20px 0; }
    #side .logo3 img {
      width: 154px;
      height: 51px; }
  #side .openclose {
    display: none; }
  #side .nav {
    width: 193px;
    margin: 0 auto; }
    #side .nav li {
      padding: 10px 0 0;
      background: url(../images/nav_bg.png) 0 0 no-repeat; }
      #side .nav li a {
        display: block; }
      #side .nav li:nth-of-type(1) a:hover {
        background: url(../images/nav_whatsnew_on.png) no-repeat right top; }
        #side .nav li:nth-of-type(1) a:hover img {
          opacity: 0.1; }
      #side .nav li:nth-of-type(2) a:hover {
        background: url(../images/nav_about_on.png) no-repeat right top; }
        #side .nav li:nth-of-type(2) a:hover img {
          opacity: 0.1; }
      #side .nav li:nth-of-type(3) a:hover {
        background: url(../images/nav_groupabout_on.png) no-repeat right top; }
        #side .nav li:nth-of-type(3) a:hover img {
          opacity: 0.1; }
      #side .nav li:nth-of-type(4) a:hover {
        background: url(../images/nav_merit_on.png) no-repeat right top; }
        #side .nav li:nth-of-type(4) a:hover img {
          opacity: 0.1; }
      #side .nav li:nth-of-type(5) a:hover {
        background: url(../images/nav_education_on.png) no-repeat right top; }
        #side .nav li:nth-of-type(5) a:hover img {
          opacity: 0.1; }
      #side .nav li:nth-of-type(6) a:hover {
        background: url(../images/nav_facilities_on.png) no-repeat right top; }
        #side .nav li:nth-of-type(6) a:hover img {
          opacity: 0.1; }
      #side .nav li:nth-of-type(7) a:hover {
        background: url(../images/nav_recruit_on.png) no-repeat right top; }
        #side .nav li:nth-of-type(7) a:hover img {
          opacity: 0.1; }

#footer .copy {
  position: absolute;
  right: 10px;
  bottom: 10px;
  font-size: 12px;
  color: #000000; }

#totop {
  bottom: 0;
  right: 20px;
  width: 60px;
  padding-bottom: 30px; }
  #totop img {
    width: 100%;
    height: auto;
    display: block; }

section {
  margin-bottom: 4rem; }
  section:last-child {
    margin-bottom: 1rem; }
  section h2 {
    color: #fff;
    background-color: #4a62bc;
    border: 2px solid #fff;
    border-radius: 3px;
    box-shadow: 0 0 1.5px 1.5px rgba(128, 128, 128, 0.375);
    margin-top: 25px;
    margin-bottom: 25px;
    font-size: 32px;
    letter-spacing: 0.1em;
    text-align: center;
    padding: 3px;
    font-weight: 200; }
  section h3 {
    color: #fff;
    background-color: #66a93a;
    border-radius: 3px;
    margin-top: 25px;
    margin-bottom: 10px;
    font-size: 18px;
    letter-spacing: 0.1em;
    text-align: center;
    padding: 3px;
    opacity: 0.75;
    font-weight: 500; }
  section p {
    margin: 2em 0;
    line-height: 2em;
    text-align: justify; }
  section.green p {
    color: #4a62bc;
    font-size: 15px;
    text-align: center;
    font-family: "YakuHanJP", "Sawarabi Mincho", "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif; }
  section.aisatsu .left {
    width: 204px; }
    section.aisatsu .left p.photo {
      margin: 0 0 0.5em; }
    section.aisatsu .left dl {
      text-align: center;
      border-bottom: 1px solid #444;
      padding-bottom: 3px;
      letter-spacing: 0.2em; }
      section.aisatsu .left dl dt {
        font-size: 13px; }
      section.aisatsu .left dl dd {
        font-size: 22px; }
  section.aisatsu .right {
    width: 393px; }
    section.aisatsu .right p:first-of-type {
      margin-top: -0.5em; }
  section.enkaku > dl {
    border-bottom: 1px solid #444;
    display: block; }
    section.enkaku > dl > dt {
      color: #8ac762;
      display: table-cell;
      vertical-align: top;
      padding: 0.6rem 0 0;
      width: 48px;
      text-align: center;
      font-family: "YakuHanJP", "Ubuntu Condensed", sans-serif;
      font-size: 22px; }
      section.enkaku > dl > dt span {
        font-size: inherit;
        color: inherit;
        display: block;
        font-family: inherit;
        margin: -4px 0 0; }
    section.enkaku > dl:nth-of-type(3n-1) > dt {
      color: #62c7c1; }
    section.enkaku > dl:nth-of-type(3n) > dt {
      color: #e4be38; }
    section.enkaku > dl:last-of-type {
      border-bottom: none; }
    section.enkaku > dl > dd {
      display: table-cell;
      vertical-align: top; }
      section.enkaku > dl > dd dl {
        display: block;
        border-bottom: 1px dashed #444; }
        section.enkaku > dl > dd dl:last-of-type {
          border-bottom: none; }
        section.enkaku > dl > dd dl dt {
          font-size: 12px;
          display: table-cell;
          vertical-align: top;
          padding: 1rem 0;
          width: 107px;
          border-right: 1px solid #444; }
        section.enkaku > dl > dd dl dd {
          font-size: 12px;
          display: table-cell;
          vertical-align: top; }
          section.enkaku > dl > dd dl dd:nth-of-type(1) {
            width: 427px;
            padding: 1rem 0 1rem 1rem; }
          section.enkaku > dl > dd dl dd:nth-of-type(2) {
            width: 58px;
            font-size: 14px;
            padding: 1rem 0; }
            section.enkaku > dl > dd dl dd:nth-of-type(2) span {
              font-size: 22px;
              line-height: 14px;
              vertical-align: inherit;
              font-family: "YakuHanJP", "Ubuntu Condensed", sans-serif; }
  section.class h2 {
    background: #61983d;
    margin-bottom: 10px; }
  section.class:nth-of-type(3n-1) h2 {
    background: #52b6af; }
  section.class:nth-of-type(3n) h2 {
    background: #dfc261; }
  section.class .mainphoto {
    margin: 10px 0; }
    section.class .mainphoto img {
      width: 100%;
      height: auto;
      display: block; }
  section.class .float {
    margin: 10px 0; }
    section.class .float .left {
      width: 258px; }
    section.class .float .right {
      width: 364px; }
    section.class .float p {
      margin: 0 0 10px; }
    section.class .float .catch {
      margin: 0 0 10px;
      font-size: 24px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      height: 251px;
      color: #fff;
      padding: 16px 0 0 21px;
      font-weight: 200;
      line-height: 38px;
      letter-spacing: -0.02em; }
      section.class .float .catch .bold {
        font-weight: 500; }
      section.class .float .catch img {
        width: 258px;
        height: auto;
        display: block; }
    section.class .float .sub {
      margin: 10px 0; }
      section.class .float .sub img {
        width: 258px;
        height: auto;
        display: block; }
    section.class .float:nth-of-type(2n) .left {
      width: 364px; }
    section.class .float:nth-of-type(2n) .right {
      width: 258px; }
  section.class .list {
    margin: 36px 0; }
    section.class .list dt {
      color: #61983d;
      font-size: 20px;
      text-align: center;
      padding-bottom: 0.5em; }
    section.class .list dd ol {
      text-align: center; }
      section.class .list dd ol li {
        width: 155px;
        display: inline-block; }
        section.class .list dd ol li img {
          width: 155px;
          height: auto;
          display: block; }
  section.class#nou .catch {
    background-image: url(../images/merit_nou_catch.jpg); }
  section.class#kyuukyuu .catch {
    background-image: url(../images/merit_kyuukyuu_catch.jpg); }
  section.class#gan .catch {
    background-image: url(../images/merit_gan_catch.jpg); }
  section.class#shinzou .catch {
    background-image: url(../images/merit_shinzou_catch.jpg); }
  section.class#fukushi .catch {
    background-image: url(../images/merit_fukushi_catch.jpg); }
  section.class#touseki .catch {
    background-image: url(../images/merit_touseki_catch.jpg); }
  section.class#shuusanki .catch {
    background-image: url(../images/merit_shuusanki_catch.jpg); }
  section.class#hyouka .catch {
    background-image: url(../images/merit_hyouka_catch.jpg); }
  section.class#shokuin .catch {
    background-image: url(../images/education_shokuin_catch.jpg); }
  section.class#rinshou .catch {
    background-image: url(../images/education_rinshou_catch.jpg); }
  section.links h2 {
    background: #8ac762; }
  section.links h2 + p {
    color: #6c6c6c;
    font-size: 15px;
    border: 2px solid #6c6c6c;
    text-align: center;
    border-radius: 15px;
    width: 325px;
    margin-left: auto;
    margin-right: auto; }
    section.links h2 + p:before, section.links h2 + p:after {
      content: "";
      width: 0;
      height: 0;
      border-color: transparent;
      border-width: 0.7em 0.5em;
      border-style: solid;
      border-top-color: #6c6c6c;
      display: inline-block;
      vertical-align: bottom; }
    section.links h2 + p:before {
      margin-right: 0.5em; }
    section.links h2 + p:after {
      margin-left: 0.5em; }
  section.links ul li {
    margin: 0.75em 0; }
    section.links ul li a {
      display: block;
      color: #66a93a;
      font-size: 18px;
      font-weight: 500; }
      section.links ul li a:before {
        content: "";
        width: 1em;
        height: 1em;
        line-height: 1em;
        background: #66a93a;
        display: inline-block;
        margin-right: 1em;
        vertical-align: middle; }
  section.links:nth-of-type(3n-1) h2 {
    background: #d4b523; }
  section.links:nth-of-type(3n-1) h3 {
    background: #ba9f1d; }
  section.links:nth-of-type(3n-1) ul li a {
    color: #ba9f1d; }
    section.links:nth-of-type(3n-1) ul li a:before {
      background: #ba9f1d; }
  section.facilities h2 {
    background: #8ac762; }
  section.facilities ul {
    width: 620px;
    margin: 0 auto; }
    section.facilities ul li {
      border: 2px solid rgba(166, 213, 137, 0.75);
      box-shadow: 0 0 1.5px 1.5px rgba(128, 128, 128, 0.375);
      background: #fff;
      width: 293px;
      padding: 7px;
      margin: 8.5px;
      border-radius: 3px; }
      section.facilities ul li dt {
        font-size: 18px;
        color: #66a93a;
        border-bottom: 1px solid #66a93a;
        height: 47px;
        padding-bottom: 4px;
        vertical-align: middle;
        display: table-cell;
        line-height: 1.3em;
        width: 275px; }
        section.facilities ul li dt span {
          display: block;
          color: inherit;
          font-size: 13px;
          line-height: 1em; }
      section.facilities ul li dd .left {
        width: 152px; }
        section.facilities ul li dd .left .address {
          margin: 4px 0 0;
          font-size: 13px;
          line-height: 1.3em;
          height: 32px; }
        section.facilities ul li dd .left .tel {
          font-size: 18px;
          line-height: 1em;
          color: #66a93a;
          margin: 4px 0 0;
          font-family: "YakuHanJP", "Ubuntu Condensed", sans-serif; }
          section.facilities ul li dd .left .tel a {
            font-size: inherit;
            line-height: inherit;
            color: inherit;
            font-family: inherit; }
        section.facilities ul li dd .left .web {
          margin: 8px 0 0; }
          section.facilities ul li dd .left .web a {
            display: block;
            background: #a0d880;
            color: #fff;
            font-size: 18px;
            opacity: 0.75;
            text-align: center;
            padding: 14px 0;
            line-height: 1em;
            border-radius: 3px;
            border: 2px solid #fff;
            box-shadow: 0 0 1.5px 1.5px rgba(128, 128, 128, 0.375); }
            section.facilities ul li dd .left .web a:hover {
              opacity: 1; }
      section.facilities ul li dd .right {
        width: 115px; }
        section.facilities ul li dd .right p {
          margin: 5px 0 0; }
          section.facilities ul li dd .right p img {
            width: 100%;
            height: auto;
            display: block; }
  section.facilities:nth-of-type(3n-1) h2 {
    background: #d4b523; }
  section.facilities:nth-of-type(3n-1) ul li {
    border: 2px solid rgba(222, 199, 90, 0.75); }
    section.facilities:nth-of-type(3n-1) ul li dt {
      color: #ba9f1d;
      border-bottom-color: #ba9f1d; }
    section.facilities:nth-of-type(3n-1) ul li dd .left .address {
      height: 47px; }
    section.facilities:nth-of-type(3n-1) ul li dd .left .tel {
      color: #ba9f1d; }
    section.facilities:nth-of-type(3n-1) ul li dd .left .web a {
      background: #dec85a; }
  section.facilities:nth-of-type(3n) h2 {
    background: #9d9d9d; }
  section.facilities:nth-of-type(3n) ul li {
    border: 2px solid rgba(198, 200, 200, 0.75); }
    section.facilities:nth-of-type(3n) ul li dt {
      color: #7d7d7d;
      border-bottom-color: #7d7d7d; }
    section.facilities:nth-of-type(3n) ul li dd .left .plan {
      background: #b4b6b5;
      color: #fff;
      border-radius: 3px;
      border: 2px solid #fff;
      box-shadow: 0 0 1.5px 1.5px rgba(128, 128, 128, 0.375);
      font-size: 18px;
      line-height: 1.5em;
      text-align: center;
      margin: 5px 0 0;
      padding: 7px 0; }

nav.class {
  text-align: center;
  margin-left: 7px; }
  nav.class li {
    margin-right: 7px;
    margin-bottom: 7px;
    display: inline-block; }
    nav.class li a {
      background: #61983d;
      color: #61983d;
      border: 2px solid #fff;
      border-radius: 3px;
      box-shadow: 0 0 1.5px 1.5px rgba(128, 128, 128, 0.5);
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      width: 117px;
      height: 66px;
      font-size: 15px;
      line-height: 15px;
      letter-spacing: 0.1em; }
      nav.class li a:before {
        background: #61983d; }
    nav.class li:nth-of-type(3n-1) a {
      background: #5cbeb7;
      color: #5cbeb7; }
      nav.class li:nth-of-type(3n-1) a:before {
        background: #5cbeb7; }
    nav.class li:nth-of-type(3n) a {
      background: #d6ad2c;
      color: #d6ad2c; }
      nav.class li:nth-of-type(3n) a:before {
        background: #d6ad2c; }
    nav.class li.big a {
      color: #fff; }
    nav.class li.small {
      margin-right: 20px; }
      nav.class li.small:last-of-type {
        margin-right: 0; }
      nav.class li.small a {
        background: transparent;
        border: none;
        box-shadow: none;
        width: auto;
        height: auto; }
        nav.class li.small a:before {
          content: "";
          width: 0.8em;
          height: 0.8em;
          line-height: 0.8em;
          display: inline-block;
          border: 2px solid #fff;
          border-radius: 3px;
          box-shadow: 0 0 1.5px 1.5px rgba(128, 128, 128, 0.5);
          margin-right: 0.5em;
          vertical-align: middle; }

#slidebgs .slidebg {
  display: block;
  position: fixed;
  z-index: -1;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-size: cover;
  background-repeat: no-repeat;
  transition: opacity 2s;
  opacity: 0; }

#slidebgs .slidebg.show {
  opacity: 1; }

#main.home {
  padding-top: 350px; }

.home h1 > span {
  font-size: 13px;
  letter-spacing: 0.7em;
  line-height: 1em;
  font-family: "YakuHanJP", "Sawarabi Mincho", "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  vertical-align: middle;
  display: block;
  margin-top: 37px; }
  .home h1 > span span {
    font-size: 24px;
    color: inherit;
    letter-spacing: 0.5em;
    font-family: inherit;
    vertical-align: inherit; }
  .home h1 > span br {
    display: none; }
  .home h1 > span.anshin {
    color: #4d991a;
    font-family: "Noto Sans Japanese", "Hiragino Kaku Gothic ProN", Meiryo, sans-serif; }
  .home h1 > span.shinrai {
    color: #24908a;
    font-family: "Noto Sans Japanese", "Hiragino Kaku Gothic ProN", Meiryo, sans-serif; }
  .home h1 > span.kouken {
    color: #a78200;
    font-family: "Noto Sans Japanese", "Hiragino Kaku Gothic ProN", Meiryo, sans-serif; }

.home .home-text img {
  position: inherit;
  top: 35%;
  left: 21%; }

.home .merit {
  position: relative;
  top: 52%;
  width: 756px;
  margin-top: 26px; }
  .home .merit li {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    width: 136px;
    height: 136px;
    margin-right: 8px; }
    .home .merit li a {
      display: block;
      width: 100%;
      height: 100%; }
  .home .merit span {
    display: block;
    color: #fff;
    text-align: center;
    letter-spacing: 0.1em; }
    .home .merit span.caption {
      font-size: 14px;
      height: 33px;
      padding-top: 20px;
      font-weight: 600; }
    .home .merit span.num {
      font-size: 32px;
      line-height: 1em;
      font-family: "YakuHanJP", "Ubuntu Condensed", sans-serif;
      padding-top: 10px;
      font-weight: 600; }
    .home .merit span.title {
      font-size: 15px;
      line-height: 16px;
      height: 32px;
      display: table-cell;
      width: 139px;
      vertical-align: bottom;
      padding-top: 10px;
      font-weight: 600; }

.home .whatsnew {
  position: relative;
  top: 72%;
  width: 756px;
  height: 287px;
  background: rgba(255, 255, 255, 0.75);
  box-shadow: 0 0 1.5px 1.5px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 20px;
  margin-top: 15px; }
  .home .whatsnew h2 {
    font-family: "YakuHanJP", "Ubuntu Condensed", sans-serif;
    color: #4a62bc;
    letter-spacing: 0.5em;
    font-size: 24px;
    border-bottom: 2px solid #4a62bc; }
  .home .whatsnew ul {
    margin-top: 20px;
    height: 177px;
    overflow-y: scroll;
    overflow-x: hidden; }
    .home .whatsnew ul::-webkit-scrollbar {
      all: unset;
      background: #939393;
      width: 7px;
      border-radius: 3.5px; }
    .home .whatsnew ul::-webkit-scrollbar-thumb {
      all: unset;
      background: #e4be38;
      border-radius: 3.5px; }
    .home .whatsnew ul li {
      padding: 20px 0;
      border-bottom: 1px dotted #8e8e8e;
      width: 483px; }
      .home .whatsnew ul li .date {
        color: #4a62bc;
        font-family: "YakuHanJP", "Ubuntu Condensed", sans-serif;
        font-size: 16px;
        letter-spacing: 0.1em;
        display: block; }
      .home .whatsnew ul li .text {
        display: block;
        color: #848484;
        line-height: 1.5em;
        margin-top: 0.25em; }
      .home .whatsnew ul li:first-of-type {
        padding-top: 0; }
      .home .whatsnew ul li:last-of-type {
        border-bottom: none;
        padding-bottom: 0; }

#bottom-image {
  height: 340px; }
  #bottom-image img {
    position: absolute;
    bottom: 0; }

@media screen and (max-width: 980px) {
  br.sp {
    display: inline; }
  .sp-only {
    display: inherit; }
  .pc-only {
    display: none !important; }
  .float > .left {
    float: none; }
  .float > .right {
    float: none; }
  .sp-header {
    width: 100%;
    height: 16vw;
    background-image: url(../images/header-sp.png);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1; }
    .sp-header .logo1 {
      position: fixed;
      top: 2vw;
      left: 2vw;
      width: 17vw; }
      .sp-header .logo1 img {
        width: 100%;
        height: auto;
        content: url(../images/logo1_sp.png); }
    .sp-header .logo2 {
      position: fixed;
      top: 2vw;
      left: 22vw;
      width: 27vw; }
      .sp-header .logo2 img {
        width: 100%;
        height: auto;
        content: url(../images/logo2_sp.png); }
    .sp-header .logo3 {
      position: fixed;
      top: 9vw;
      left: 22vw;
      width: 27vw; }
      .sp-header .logo3 img {
        width: 100%;
        height: auto;
        content: url(../images/logo3_sp.png); }
  .wrapper {
    width: auto;
    height: auto; }
    .wrapper .left {
      position: static; }
  #main.right {
    padding-top: 17vw; }
  #main {
    width: auto;
    margin: 0;
    padding: 0; }
    #main > .inner {
      margin: 5vw 5vw 5vw;
      padding: 5vw; }
      #main > .inner h1 {
        font-size: 18px;
        margin-bottom: 5vw; }
      #main > .inner h2 {
        font-size: 20px;
        margin: 5vw 0;
        padding: 2.5vw; }
  .stickyinner.sticky {
    top: initial;
    bottom: 30px; }
  #side {
    background: transparent;
    width: auto;
    height: auto; }
    #side .inner {
      width: auto;
      height: auto;
      background: none;
      padding: 0; }
      #side .inner:last-of-type {
        height: auto; }
    #side .glonav p {
      border-bottom: none;
      padding: 0;
      width: auto;
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center; }
      #side .glonav p a {
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        width: 100%;
        height: 100%; }
    #side .glonav .logo1 {
      position: fixed;
      top: 2vw;
      left: 2vw;
      width: 17vw; }
      #side .glonav .logo1 img {
        width: 100%;
        height: auto;
        content: url(../images/logo1_sp.png); }
    #side .glonav .logo2 {
      position: fixed;
      top: 4vw;
      left: 22vw;
      width: 27vw;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      overflow: hidden; }
      #side .glonav .logo2 img {
        width: 100%;
        height: auto;
        content: url(../images/logo2_sp.png); }
    #side .glonav .logo3 {
      position: fixed;
      top: 11vw;
      left: 22vw;
      width: 27vw;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      overflow: hidden; }
      #side .glonav .logo3 img {
        width: 100%;
        height: auto;
        content: url(../images/logo3_sp.png); }
    #side .glonav .openclose {
      position: fixed;
      left: 2.5vw;
      bottom: 6vw;
      width: 15vw;
      height: 15vw;
      z-index: 1002; }
      #side .glonav .openclose span {
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        width: auto;
        height: auto; }
        #side .glonav .openclose span img {
          width: 15vw;
          height: auto; }
    #side .nav {
      background: rgba(5, 26, 106, 0.7);
      width: 70vw;
      padding: 5vw;
      display: none; }
      #side .nav li {
        padding: 0;
        background-image: none;
        margin-bottom: 6vw; }
        #side .nav li a {
          text-align: center; }
          #side .nav li a img {
            width: 100%;
            height: auto;
            display: block; }
        #side .nav li:nth-of-type(1) img {
          content: url(../images/nav_whatsnew_sp_off.png); }
          #side .nav li:nth-of-type(1) img.active, #side .nav li:nth-of-type(1) img:hover {
            content: url(../images/nav_whatsnew_sp_on.png);
            background-image: none; }
        #side .nav li:nth-of-type(2) img {
          content: url(../images/nav_about_sp_off.png); }
          #side .nav li:nth-of-type(2) img.active, #side .nav li:nth-of-type(2) img:hover {
            content: url(../images/nav_about_sp_on.png);
            background-image: none; }
        #side .nav li:nth-of-type(3) img {
          content: url(../images/nav_groupabout_sp_off.png); }
          #side .nav li:nth-of-type(3) img.active, #side .nav li:nth-of-type(3) img:hover {
            content: url(../images/nav_groupabout_sp_on.png);
            background-image: none; }
        #side .nav li:nth-of-type(4) img {
          content: url(../images/nav_merit_sp_off.png); }
          #side .nav li:nth-of-type(4) img.active, #side .nav li:nth-of-type(4) img:hover {
            content: url(../images/nav_merit_sp_on.png);
            background-image: none; }
        #side .nav li:nth-of-type(5) img {
          content: url(../images/nav_education_sp_off.png); }
          #side .nav li:nth-of-type(5) img.active, #side .nav li:nth-of-type(5) img:hover {
            content: url(../images/nav_education_sp_on.png);
            background-image: none; }
        #side .nav li:nth-of-type(6) img {
          content: url(../images/nav_facilities_sp_off.png); }
          #side .nav li:nth-of-type(6) img.active, #side .nav li:nth-of-type(6) img:hover {
            content: url(../images/nav_facilities_sp_on.png);
            background-image: none; }
        #side .nav li:nth-of-type(7) img {
          content: url(../images/nav_recruit_sp_off.png); }
          #side .nav li:nth-of-type(7) img.active, #side .nav li:nth-of-type(7) img:hover {
            content: url(../images/nav_recruit_sp_on.png);
            background-image: none; }
  .spmenubg {
    bottom: 15vw !important; }
  #footer .copy {
    position: static;
    padding: 0 5vw 20vw;
    text-align: center; }
  #totop {
    right: 2.5vw;
    width: 15vw;
    padding-bottom: 2.5vw; }
  section {
    margin-bottom: 5vw; }
    section h2 {
      opacity: 1;
      font-size: 28px !important; }
    section h3 {
      opacity: 1; }
    section p {
      margin: 5vw 0; }
      section p img {
        max-width: 100%;
        display: block;
        margin-left: auto;
        margin-right: auto; }
    section.green p {
      text-align: justify; }
      section.green p br {
        display: none; }
    section.aisatsu .left {
      width: auto; }
      section.aisatsu .left p.photo {
        text-align: center; }
    section.aisatsu .right {
      width: auto;
      margin-top: 5vw; }
    section.enkaku > dl {
      padding: 5vw 0 0; }
      section.enkaku > dl > dt {
        display: block;
        padding: 0;
        width: auto; }
      section.enkaku > dl > dd {
        display: block; }
        section.enkaku > dl > dd dl {
          padding: 1em 0; }
          section.enkaku > dl > dd dl dt {
            display: block;
            padding: 0;
            width: auto;
            border-right: none;
            margin: 0 0 0.5em;
            text-align: center; }
          section.enkaku > dl > dd dl dd {
            display: block;
            text-align: center; }
            section.enkaku > dl > dd dl dd:nth-of-type(1) {
              width: auto;
              padding: 0; }
            section.enkaku > dl > dd dl dd:nth-of-type(2) {
              width: auto;
              padding: 0;
              font-size: 12px;
              margin-top: 0.5em; }
              section.enkaku > dl > dd dl dd:nth-of-type(2) span {
                font-size: 16px;
                font-weight: bold; }
    section.class h2 {
      margin-bottom: 2.5vw; }
    section.class .mainphoto {
      margin: 2.5vw 0; }
      section.class .mainphoto img {
        max-width: 100%; }
    section.class .float {
      margin: 2.5vw 0; }
      section.class .float .left {
        width: auto;
        display: flex;
        justify-content: space-between;
        margin: 2.5vw 0; }
      section.class .float .right {
        width: auto; }
      section.class .float .catch {
        width: 80vw;
        height: 77.83vw;
        font-size: 7.13vw;
        line-height: 1.5em;
        padding: 5vw;
        width: 40vw;
        height: 38.9vw;
        font-size: 3.8vw;
        padding: 2vw;
        margin: 0; }
        section.class .float .catch img {
          width: 100%; }
      section.class .float .sub {
        width: 37.5vw;
        margin: 0; }
        section.class .float .sub img {
          width: 100%; }
      section.class .float:nth-of-type(2n) .left {
        width: 100%; }
      section.class .float:nth-of-type(2n) .right {
        width: 100%; }
    section.class .list {
      margin: 5vw 0; }
      section.class .list dt {
        text-align: left;
        font-size: 14px; }
      section.class .list dd ol {
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-lines: multiple;
        -ms-flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap; }
        section.class .list dd ol li {
          width: 40vw;
          padding: 1vw; }
          section.class .list dd ol li img {
            width: 100%; }
    section.links h2 + p {
      width: auto;
      font-size: 12px; }
    section.links ul li a {
      font-size: 14px; }
    section.facilities ul {
      width: auto; }
      section.facilities ul li {
        width: auto;
        margin: 5vw 0;
        padding: 2.5vw; }
        section.facilities ul li dt {
          width: 75vw; }
        section.facilities ul li dd .left {
          width: auto; }
          section.facilities ul li dd .left .address {
            height: auto; }
          section.facilities ul li dd .left .web a {
            opacity: 1; }
        section.facilities ul li dd .right {
          width: auto; }
          section.facilities ul li dd .right p img {
            width: 75%;
            margin: 5vw auto; }
    section.facilities:nth-of-type(3n-1) ul li dd .left .address {
      height: auto; }
  nav.class li a {
    opacity: 1;
    width: 115px; }
  nav.class li.small {
    display: block; }
  #main.home {
    padding: 0; }
    #main.home h1 {
      padding-top: 100vw; }
      #main.home h1 > span {
        letter-spacing: 0.1em;
        margin-top: 3vw;
        padding: 0 5vw;
        font-size: 4vw;
        line-height: 1.4em;
        vertical-align: middle; }
        #main.home h1 > span span {
          margin-bottom: 1rem;
          font-size: 5.5vw; }
        #main.home h1 > span br {
          display: inline; }
    #main.home .home-text img {
      position: inherit;
      width: 100%; }
    #main.home .merit {
      margin-top: 1vw;
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-lines: multiple;
      -ms-flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
      flex-wrap: wrap;
      position: inherit;
      width: auto; }
      #main.home .merit .left img {
        width: 100%; }
      #main.home .merit li {
        width: 29vw;
        height: 29vw;
        margin: 0 1vw 1vw 0;
        background-size: cover; }
        #main.home .merit li:nth-of-type(3n) {
          margin-right: 0; }
        #main.home .merit li .caption {
          font-size: 3vw;
          height: 7.5vw;
          padding-top: 2vw; }
        #main.home .merit li .num {
          display: -webkit-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          -webkit-justify-content: center;
          justify-content: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;
          height: 11vw;
          font-size: 13vw; }
        #main.home .merit li .title {
          letter-spacing: 0;
          font-size: 3.5vw;
          line-height: 1.2em;
          height: 8vw;
          width: 29vw; }
    #main.home .whatsnew {
      width: 100%;
      height: 50vw;
      padding: 5vw;
      margin: 5vw auto;
      top: 100%;
      position: inherit; }
      #main.home .whatsnew h2 {
        font-size: 16px; }
      #main.home .whatsnew ul {
        margin-top: 5vw;
        height: 29vw; }
        #main.home .whatsnew ul li {
          width: 70vw; } }
