#slidebgs .slidebg {
  display: block;
  position: fixed;
  z-index: -1;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-size: cover;
  background-repeat: no-repeat;
  transition: opacity 2s;
  opacity: 0;
}

#slidebgs .slidebg.show {
  opacity: 1;
}

#main.home {
  padding-top: 350px;
}

.home {
  h1 {
    > span {
      font-size: 13px;
      letter-spacing: 0.7em;
      line-height: 1em;
      @include font(mincho);
      vertical-align: middle;
      display: block;
      margin-top: 37px;
      span {
        font-size: 24px;
        color: inherit;
        letter-spacing: 0.5em;
        font-family: inherit;
        vertical-align: inherit;
      }
      br {
        display: none;
      }
      &.anshin {
        color: #4d991a;
        font-family: "Noto Sans Japanese", "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
      }
      &.shinrai {
        color: #24908a;
        font-family: "Noto Sans Japanese", "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
      }
      &.kouken {
        color: #a78200;
        font-family: "Noto Sans Japanese", "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
      }
    }
  }
  .home-text {
    img {
      position: inherit;
      top: 35%;
      left: 21%;
    }
  }
  .merit {
    position: relative;
    top: 52%;
    width: 756px;
    margin-top: 26px;
    li {
      background-position: 50% 50%;
      background-repeat: no-repeat;
      width: 136px;
      height: 136px;
      margin-right: 8px;
      //&:nth-of-type(1) {
      //  background-image: url(../images/home_01.png);
      //}
      //&:nth-of-type(2) {
      //  background-image: url(../images/home_02.png);
      //}
      //&:nth-of-type(3) {
      //  background-image: url(../images/home_03.png);
      //}
      //&:nth-of-type(4) {
      //  background-image: url(../images/home_04.png);
      //}
      //&:nth-of-type(5) {
      //  background-image: url(../images/home_05.png);
      //  margin-right: 0;
      //}
      a {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    span {
      display: block;
      color: #fff;
      text-align: center;
      letter-spacing: 0.1em;
      &.caption {
        font-size: 14px;
        height: 33px;
        padding-top: 20px;
        font-weight: 600;
      }
      &.num {
        font-size: 32px;
        line-height: 1em;
        @include font(alphabet);
        padding-top: 10px;
        font-weight: 600;
      }
      &.title {
        font-size: 15px;
        line-height: 16px;
        height: 32px;
        display: table-cell;
        width: 139px;
        vertical-align: bottom;
        padding-top: 10px;
        font-weight: 600;
      }
    }
  }
  .whatsnew {
    position: relative;
    top: 72%;
    width: 756px;
    height: 287px;
    background: rgba(#fff, 0.75);
    box-shadow: 0 0 1.5px 1.5px rgba(#000, 0.15);
    border-radius: 5px;
    padding: 20px;
    margin-top: 15px;
    h2 {
      @include font(alphabet);
      color: #4a62bc;
      letter-spacing: 0.5em;
      font-size: 24px;
      border-bottom: 2px solid #4a62bc;
    }
    ul {
      margin-top: 20px;
      height: 177px;
      overflow-y: scroll;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        all: unset;
        background: #939393;
        width: 7px;
        border-radius: 3.5px;
      }
      &::-webkit-scrollbar-thumb {
        all: unset;
        background: #e4be38;
        border-radius: 3.5px;
      }
      li {
        padding: 20px 0;
        border-bottom: 1px dotted #8e8e8e;
        width: 483px;
        .date {
          color: #4a62bc;
          @include font(alphabet);
          font-size: 16px;
          letter-spacing: 0.1em;
          display: block;
        }
        .text {
          display: block;
          color: #848484;
          line-height: 1.5em;
          margin-top: 0.25em;
        }
        &:first-of-type {
          padding-top: 0;
        }
        &:last-of-type {
          border-bottom: none;
          padding-bottom: 0;
        }
      }
    }
  }
}

#bottom-image {
  height: 340px;
  img {
    position: absolute;
    bottom: 0;
  }
}