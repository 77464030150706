.stickyinner.sticky {
  position: fixed;
  padding-left: 12px;
  top: 0;
}

#side {
  background-color: rgba(#051a6a, 0.7);
  // background-image: url(../images/nav_bg.png);
  // background-repeat: repeat-y;
  background-position: 12px 0;
  width: 218px;
  height: 100%;
  .inner {
    width: 193px;
    margin: 0 auto;
    background: url(../images/nav_bg.png) 0 0 repeat-y;
    padding-left: 10px;
    padding-bottom: 40px;
    .glonav {
      padding: 0 0 150px;
    }
    //&:last-of-type{
    //	height: calc(100% - 374px - 322px - 150px);
    //	padding-bottom: 0;
    //}
  }
  .logo1,
  .logo2,
  .logo3 {
    width: 185px;
    border-bottom: 1px solid #fff;
    img {
      display: block;
      margin: 0 auto;
    }
  }
  .logo1 {
    padding: 40px 0 45px;
    img {
      width: 138px;
      height: 104px;
    }
  }
  .logo2 {
    padding: 20px 0;
    img {
      width: 96px;
      height: 51px;
    }
  }
  .logo3 {
    padding: 20px 0;
    img {
      width: 154px;
      height: 51px;
    }
  }
  .openclose {
    display: none;
  }
  .nav {
    //background: url(../images/nav_bg.png) 0 0 repeat-y;

    width: 193px;
    margin: 0 auto;
    li {
      padding: 10px 0 0;
      background: url(../images/nav_bg.png) 0 0 no-repeat;
      a {
        display: block;
      }
      &:nth-of-type(1) a:hover{
        background: url(../images/nav_whatsnew_on.png) no-repeat right top;
        img{
          opacity: 0.1;
        }
      }
      &:nth-of-type(2) a:hover{
        background: url(../images/nav_about_on.png) no-repeat right top;
        img{
          opacity: 0.1;
        }
      }
      &:nth-of-type(3) a:hover{
        background: url(../images/nav_groupabout_on.png) no-repeat right top;
        img{
          opacity: 0.1;
        }
      }
      &:nth-of-type(4) a:hover{
        background: url(../images/nav_merit_on.png) no-repeat right top;
        img{
          opacity: 0.1;
        }
      }
      &:nth-of-type(5) a:hover{
        background: url(../images/nav_education_on.png) no-repeat right top;
        img{
          opacity: 0.1;
        }
      }
      &:nth-of-type(6) a:hover{
        background: url(../images/nav_facilities_on.png) no-repeat right top;
        img{
          opacity: 0.1;
        }
      }
      &:nth-of-type(7) a:hover{
        background: url(../images/nav_recruit_on.png) no-repeat right top;
        img{
          opacity: 0.1;
        }
      }
    }
  }
}