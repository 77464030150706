section {
  margin-bottom: 5vw;
  h2 {
    opacity: 1;
    font-size: 28px !important;
  }
  h3 {
    opacity: 1;
  }
  p {
    margin: 5vw 0;
    img {
      max-width: 100%;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
  &.green {
    p {
      text-align: justify;
      br {
        display: none;
      }
    }
  }
  &.aisatsu {
    .left {
      width: auto;
      p.photo {
        text-align: center;
      }
    }
    .right {
      width: auto;
      margin-top: 5vw;
    }
  }
  &.enkaku {
    > dl {
      padding: 5vw 0 0;
      > dt {
        display: block;
        padding: 0;
        width: auto;
      }
      > dd {
        display: block;
        dl {
          padding: 1em 0;
          &:last-of-type {
          }
          dt {
            display: block;
            padding: 0;
            width: auto;
            border-right: none;
            margin: 0 0 0.5em;
            text-align: center;
          }
          dd {
            display: block;
            text-align: center;
            &:nth-of-type(1) {
              width: auto;
              padding: 0;
            }
            &:nth-of-type(2) {
              width: auto;
              padding: 0;
              font-size: 12px;
              margin-top: 0.5em;
              span {
                font-size: 16px;
                font-weight: bold;
                //								margin-left: 1em;
                //								line-height: inherit;
              }
            }
          }
        }
      }
    }
  }
  &.class {
    h2 {
      margin-bottom: 2.5vw;
    }
    .mainphoto {
      margin: 2.5vw 0;
      img {
        max-width: 100%;
      }
    }
    .float {
      margin: 2.5vw 0;
      .left {
        width: auto;
        display: flex;
        justify-content: space-between;
        margin: 2.5vw 0;
      }
      .right {
        width: auto;
      }
      p {
      }
      .catch {
        width: 80vw;
        height: 77.83vw;
        font-size: 7.13vw;
        line-height: 1.5em;
        padding: 5vw;
        width: 40vw;
        height: 38.9vw;
        font-size: 3.8vw;
        padding: 2vw;
        margin: 0;
        img {
          width: 100%;
        }
      }
      .sub {
        width: 37.5vw;
        margin: 0;
        img {
          width: 100%;
        }
      }
      &:nth-of-type(2n) {
        .left {
          width: 100%;
        }
        .right {
          width: 100%;
        }
      }
    }
    .list {
      margin: 5vw 0;
      dt {
        text-align: left;
        font-size: 14px;
      }
      dd {
        ol {
          @include display-flex;
          @include justify-content-center;
          @include flex-wrap-wrap;
          li {
            width: 40vw;
            padding: 1vw;
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
  &.links {
    h2 + p {
      width: auto;
      font-size: 12px;
      &:before,
      &:after {
      }
      &:before {
      }
      &:after {
      }
    }
    ul {
      li {
        a {
          font-size: 14px;
          &:before {
          }
        }
      }
    }
    &:nth-of-type(3n-1) {
      h2 {
      }
      ul {
        li {
          a {
            &:before {
            }
          }
        }
      }
    }
  }
  &.facilities {
    ul {
      width: auto;
      li {
        width: auto;
        margin: 5vw 0;
        padding: 2.5vw;
        dt {
          width: 75vw;
          span {
          }
        }
        dd {
          .left {
            width: auto;
            .address {
              height: auto;
            }
            .tel {
              a {
              }
            }
            .web {
              a {
                opacity: 1;
                &:hover {
                }
              }
            }
          }
          .right {
            width: auto;
            p {
              img {
                width: 75%;
                //max-width: 18vw;
                margin: 5vw auto;
              }
            }
          }
        }
      }
    }
    &:nth-of-type(3n-1) {
      h2 {
      }
      ul {
        li {
          dt {
          }
          dd {
            .left {
              .address {
                height: auto;
              }
              .tel {
              }
              .web {
                a {
                }
              }
            }
          }
        }
      }
    }
    &:nth-of-type(3n) {
      h2 {
      }
      ul {
        li {
          dt {
          }
          dd {
            .left {
              .plan {
              }
            }
          }
        }
      }
    }
  }
}

nav.class {
  //	border: 2px solid #fff;
  //	border-radius: 3px;
  //	box-shadow: 0 0 1.5px 1.5px rgba(#808080,0.5);
  li {
    //		display: block;
    //		margin: 0;
    a {
      //			border: none;
      //			box-shadow: none;
      //			border-radius: 0;
      //			display: block;
      //			width: auto;
      //			height: auto;
      //			padding: 0.5em;
      opacity: 1;
      width: 115px;
      br {
        //				display: none;
      }
      &:before {
      }
    }
    &.big {
      a {
      }
    }
    &.small {
      display: block;
      &:last-of-type {
      }
      a {
        &:before {
        }
      }
    }
  }
}