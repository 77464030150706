#footer {
  .copy {
    position: static;
    padding: 0 5vw 20vw;
    text-align: center;
  }
}

#totop {
  right: 2.5vw;
  width: 15vw;
  padding-bottom: 2.5vw;
  img {
  }
}