.stickyinner.sticky {
  top: initial;
  bottom: 30px;
}

#side {
  background: transparent;
  width: auto;
  height: auto;
  .inner {
    width: auto;
    height: auto;
    background: none;
    padding: 0;
    &:last-of-type {
      height: auto;
    }
  }
  .glonav {
    p {
      border-bottom: none;
      padding: 0;
      width: auto;
      @include display-flex;
      @include justify-content-center;
      @include align-items-center;
      a {
        @include display-flex;
        @include justify-content-center;
        @include align-items-center;
        width: 100%;
        height: 100%;
      }
    }
    .logo1 {
      position: fixed;
      top: 2vw;
      left: 2vw;
      //position: absolute;
      //top: -6vw;
      //left: 16vw;
      width: 17vw;
      img {
        width: 100%;
        height: auto;
        content: url(../images/logo1_sp.png);
      }
    }
    .logo2 {
      position: fixed;
      top: 4vw;
      left: 22vw;
      width: 27vw;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      overflow: hidden;
      img {
        width: 100%;
        height: auto;
        content: url(../images/logo2_sp.png);
      }
    }
    .logo3 {
      position: fixed;
      top: 11vw;
      left: 22vw;
      width: 27vw;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      overflow: hidden;
      img {
        width: 100%;
        height: auto;
        content: url(../images/logo3_sp.png);
      }
    }
    .openclose {
      position: fixed;
      left: 2.5vw;
      bottom: 6vw;
      width: 15vw;
      height: 15vw;
      z-index: 1002;
      span {
        @include display-flex;
        @include justify-content-center;
        @include align-items-center;
        position: absolute;
        top: 0;
        left: 0;
        width: auto;
        height: auto;
        img {
          width: 15vw;
          height: auto;

        }
      }
    }
  }
  .nav {
    background: rgba(#051a6a, 0.7);
    width: 70vw;
    padding: 5vw;
    display: none;
    li {
      padding: 0;
      background-image: none;
      margin-bottom: 6vw;
      a {
        text-align: center;
        img {
          width: 100%;
          height: auto;
          display: block;
        }
      }
      &:nth-of-type(1) img {
        content: url(../images/nav_whatsnew_sp_off.png);
        &.active, &:hover {
          content: url(../images/nav_whatsnew_sp_on.png);
          background-image: none;
        }
      }
      &:nth-of-type(2) img {
        content: url(../images/nav_about_sp_off.png);
        &.active, &:hover {
          content: url(../images/nav_about_sp_on.png);
          background-image: none;
        }
      }
      &:nth-of-type(3) img {
        content: url(../images/nav_groupabout_sp_off.png);
        &.active, &:hover {
          content: url(../images/nav_groupabout_sp_on.png);
          background-image: none;
        }
      }
      &:nth-of-type(4) img {
        content: url(../images/nav_merit_sp_off.png);
        &.active, &:hover {
          content: url(../images/nav_merit_sp_on.png);
          background-image: none;
        }
      }
      &:nth-of-type(5) img {
        content: url(../images/nav_education_sp_off.png);
        &.active, &:hover {
          content: url(../images/nav_education_sp_on.png);
          background-image: none;
        }
      }
      &:nth-of-type(6) img {
        content: url(../images/nav_facilities_sp_off.png);
        &.active, &:hover {
          content: url(../images/nav_facilities_sp_on.png);
          background-image: none;
        }
      }
      &:nth-of-type(7) img {
        content: url(../images/nav_recruit_sp_off.png);
        &.active, &:hover {
          content: url(../images/nav_recruit_sp_on.png);
          background-image: none;
        }
      }
    }
  }
}

.spmenubg {
  bottom: 15vw !important;
}