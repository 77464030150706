#footer {
  .copy {
    position: absolute;
    right: 10px;
    bottom: 10px;
    font-size: 12px;
    color: #000000;
  }
}

#totop {
  bottom: 0;
  right: 20px;
  width: 60px;
  padding-bottom: 30px;
  img {
    width: 100%;
    height: auto;
    display: block;
  }
}